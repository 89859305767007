<template>
    <div>
        <h2><router-link :to="{ name: 'brand' }">จัดการแบรนด์</router-link> > {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "เพิ่ม" : "แก้ไข"}}แบรนด์</h2>
        <v-card class="mt-4">
          <v-card-title>ประเภทช่องทาง</v-card-title>

          <v-card-text>
            <v-form v-model="isFormValid" @submit.prevent="submitForm">              
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.name"
                    outlined
                    label="ชื่อแบรนด์ *"                               
                    :rules="[validators.required, validators.maxLengthValidator(formData.name, 50)]"
                    hide-details="auto"          
                    dense  
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <delay-autocomplete
                    :url="'/product/brand/supplierList'"                
                    label="ซัพพลายเออร์ *"                    
                    outlined
                    v-model="formData.supplier"
                    item-text="name"
                    item-value="id"
                    dense                
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    return-object
                    hide-details="auto" 
                    
                  >
                  </delay-autocomplete>
                </v-col>
              </v-row>
            </v-form>            
          </v-card-text>          

          <v-card-actions>              
            <v-btn
              color="primary"               
              large          
              :disabled="!isFormValid"
              :loading="sendingData"
              @click="submitForm"
              v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
            >
              {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "สร้าง" : "แก้ไข"}}
            </v-btn>

            <v-btn
              color="secondary"               
              outlined                 
              large
              :disabled="sendingData"
              @click="$router.go(-1)"
            >
              กลับ
            </v-btn>                
          </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import { maxLengthValidator, required } from '@/@core/utils/validation'
import { asyncGet, asyncPost, asyncUpdate } from '@/helpers/asyncAxios'
export default {
  data() {
    return {
      isFormValid: false,
      sendingData: false,
      formData: {
        name: null,
        supplier: null,
        supplier_id: null,
      },
      validators: {
        required,
        maxLengthValidator,
      },
    }
  },
  watch: {
    'formData.supplier': function (value) {
      if (!value) return (this.formData.supplier_id = null)

      this.formData.supplier_id = value.id
    },
  },
  async created() {
    const brand_id = this.$route.params.brand_id

    if (brand_id == 'create') {
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'Product'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const { name, supplier } = await asyncGet('/product/brand/' + brand_id)

      this.formData = {
        name: name,
        supplier: !!supplier ? supplier : null,
        supplier_id: !!supplier ? supplier.id : null,
      }
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      if (!this.isFormValid) return

      this.sendingData = true
      try {
        const brand_id = this.$route.params.brand_id
        if (brand_id == 'create') await asyncPost('/product/brand', this.formData)
        else {
          await asyncUpdate('/product/brand/' + brand_id, this.formData)
        }

        this.$router.push({ name: 'brand' })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
      }

      this.sendingData = false
    },
  },
  components: {},
}
</script>